@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  margin: 0;
  font-family: 'Courier New', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
     'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
body {
  @apply font-sans;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* color */
/* .bg-primary {
  background: #0F172A;
}

.bg-secondary {
  background: #1E293B;
}

.bg-accent {
  background: #7477FF;
}

.text-accent {
  color: #7477FF;
}  */
/* .bg-primary {
  background: #ffffff;
}

.bg-secondary {
  background: #f6f7f9;
} */
.bg-primary {
  background: #f6f7f9;
}

.bg-secondary {
  background: #ffffff;
}

.bg-accent {
  background: #ffafe7;
}

.bg-hover {
  background: #fe93de;
}

.text-accent {
  color: #ffafe7;
} 

.border-accent {
  border-color: #ffafe7;
}

.text-hover {
  --tw-text-opacity: 1;
  color: #fe93de /* #000000 */;
}

.mobile-nav {
  @apply block md:hidden fixed top-10 py-2 w-full h-full bg-gray-900 duration-500;
} 


nav li a {
  @apply px-4 py-5 text-lg;
}

/* .custom-position {
  object-position: 60% -3px;
} */

nav li a:hover {
  color: #7477FF;
}

/* hero */
/* h1 {
  font-family: 'Pacifico', cursive;
  line-height: 1.5 !important;
} */
